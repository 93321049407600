import { useSearchParams } from "react-router-dom";
import { Button } from "@/components";

export type actionFor = "client" | "user";

enum BUTTON_MAP {
  "user" = "Add User",
  "client" = "Add Client",
}

export const Actions = ({
  action,
  query,
}: {
  action: actionFor;
  query: string;
}) => {
  const [_, setSearchParams] = useSearchParams();

  const handleOpenModal = () => {
    setSearchParams((searchQuery) => {
      searchQuery.set(query, "true");
      return searchQuery;
    });
  };

  return <Button onClick={handleOpenModal}>{BUTTON_MAP[action]}</Button>;
};
