import { TabsWithClientActions } from "@/components";
import { ClientsTab } from "@/modules/admin/ClientTab";
import { UsersTab } from "@/modules/admin/UsersTab";
import { AdminLoading } from "@/modules/admin/AdminLoading";
import { useUpdateHeaderTitle } from "@/hooks/useUpdateHeaderData";
import { useUsers } from "@/hooks/useUsers";
import { useClients } from "@/hooks/useClients";
import { useRoles } from "@/hooks/useRoles";
import { useIndastries } from "@/hooks/useIndastry";

export default function Admin() {
  const { users, userErrors } = useUsers();
  const { clients, clientErrors } = useClients();
  const { roles, roleErrors } = useRoles();
  const { industries, industryErrors } = useIndastries();
  const errors = userErrors || clientErrors || roleErrors || industryErrors;

  useUpdateHeaderTitle("Administration");

  if (errors) {
    throw new Error(errors);
  }

  if (!(users && clients && roles && industries)) {
    return <AdminLoading />;
  }

  const clientOptions = clients.map((client) => ({
    label: client.name,
    value: client.id.toString(),
  }));

  const tabData = [
    {
      label: "Users",
      value: "users",
      content: (
        <UsersTab
          clientOptions={clientOptions}
          roleOptions={roles}
          data={users}
        />
      ),
    },
    {
      label: "Clients",
      value: "clients",
      content: <ClientsTab data={clients} industryOptions={industries} />,
    },
  ];

  return <TabsWithClientActions defaultValue="users" data={tabData} />;
}
