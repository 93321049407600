export interface SelectOption<T, K = string> {
  label: K;
  value: T;
}

export type action = "create" | "edit";

export enum APPS {
  OP = "Occupancy Planning",
  TS = "Transaction Strategy",
  PLATFORM = "Platform",
}
