import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useUpdateHeaderData } from "@/hooks/useUpdateHeaderData";
import { useMutateUser } from "@/hooks/useUsers";
import { Budge, Table, RowWithBudge } from "@/components";
import { Actions } from "./Actions";
import { UserSidebar } from "./sidebar/User";
import { User, SelectOption } from "@/types";
import styles from "./style.module.scss";

interface Props {
  data: User[];
  clientOptions: SelectOption<string>[];
  roleOptions: SelectOption<string>[];
}

enum QUERY {
  MANAGE_USER = "manage_user",
}

enum FILTERS {
  NAME = "name",
  EMAIL = "email",
  ROLE = "role",
  CLIENTS = "clients",
}

const prepareFilters = (query: URLSearchParams) => {
  const filters = {} as Record<string, string[]>;
  Object.values(FILTERS).forEach((key) => {
    if (query.has(key)) {
      filters[key] = query.get(key)!.split(",");
    }
  });
  return Object.keys(filters).length ? filters : undefined;
};

export const UsersTab = ({
  data = [],
  clientOptions = [],
  roleOptions = [],
}: Props) => {
  const [users, setUsers] = useState(data);
  const [searchParams, setSearchParams] = useSearchParams();
  const { activateToggle } = useMutateUser();

  const manageUser = searchParams.get(QUERY.MANAGE_USER);
  const activeFilters = prepareFilters(searchParams);

  const manageUserData = manageUser
    ? users.find((user) => user.id === +manageUser)
    : null;

  useUpdateHeaderData(
    "users",
    <Actions action="user" query={QUERY.MANAGE_USER} />,
  );

  const handleFiltering = useCallback(
    (key: keyof User, value: Array<string | number>) => {
      setSearchParams((query) => {
        if (value.length) {
          query.set(key, value.join(","));
        } else {
          query.delete(String(key));
        }
        return query;
      });
    },
    [setSearchParams],
  );

  const handleUserEdit = ({ id }: User) => {
    setSearchParams((query) => {
      query.set(QUERY.MANAGE_USER, String(id));
      return query;
    });
  };

  const handleClose = () => {
    setSearchParams((query) => {
      query.delete(QUERY.MANAGE_USER);
      return query;
    });
  };

  const handleActivate = async ({ id, is_active }: User) => {
    await activateToggle(id, !is_active);
    setUsers((state) => {
      const idx = state.findIndex((item) => item.id === id);
      const dataToEdit = [...state];
      dataToEdit[idx] = {
        ...dataToEdit[idx],
        is_active: !dataToEdit[idx].is_active,
      };
      return dataToEdit;
    });
  };

  const handleSubmit = (data: User, operation: "edit" | "create") => {
    setUsers((state) => {
      if (operation === "create") {
        return [data, ...state];
      }
      const idx = state.findIndex((item) => item.id === +data.id);
      const dataToEdit = [...state];
      dataToEdit[idx] = { ...dataToEdit[idx], ...data };
      return dataToEdit;
    });
    handleClose();
  };

  const ColumnWithAction = ({
    row,
    children,
  }: {
    row: User;
    children: React.ReactNode;
  }) => <div onClick={() => handleUserEdit(row)}>{children}</div>;

  return (
    <>
      <section className={styles.tabWrapper}>
        <div className={styles.number}>{users.length} users</div>
        <Table
          data={users}
          columns={columns}
          emptyMsg="There are no users to display yet."
          config={{
            columnWrapper: {
              name: ColumnWithAction,
              email: ColumnWithAction,
              role: ColumnWithAction,
              clients: ColumnWithAction,
              is_active: ColumnWithAction,
              last_seen: ColumnWithAction,
            },
            columnRenderer: {
              name: emptyModifier,
              clients: RowWithBudge,
              is_active: StatusRenderer,
              last_seen: dateModifiedRenderer,
            },
            activeFilters,
            onFilter: handleFiltering,
            sortBy: {
              name: true,
              email: true,
              role: true,
              is_active: true,
              last_seen: true,
            },
            defaultSortByColumn: "name",
            filterBy: {
              name: true,
              email: true,
              role: true,
              clients: true,
            },
            actions: [
              {
                label: "Edit User",
                onSelect: handleUserEdit,
              },
              {
                label: "",
                onSelect: handleActivate,
                custom: (row: User) =>
                  row.is_active ? "Deactivate User" : "Activate User",
              },
            ],
            defaultOrder: "asc",
            styles: {
              name: { width: "15%" },
              email: { width: "15%" },
              role: { width: "20%" },
              clients: { width: "25%" },
              is_active: { maxWidth: "max-content" },
              last_seen: { maxWidth: "max-content" },
            },
          }}
        />
      </section>
      {manageUser && (
        <UserSidebar
          onClose={handleClose}
          onSubmit={handleSubmit}
          user={manageUserData}
          clients={clientOptions}
          roles={roleOptions}
        />
      )}
    </>
  );
};

const StatusRenderer = (value: boolean) => (
  <Budge
    color={value ? "black" : "white"}
    background={value ? "#17e88f" : "#778f9c"}
  >
    {value ? "Active" : "Inactive"}
  </Budge>
);

const emptyModifier = (value: string) => value || "--";
const dateModifiedRenderer = (value: string) =>
  value ? dayjs(value).format("D MMM YYYY, HH:mm") : "--";

const columns = {
  name: "User Name",
  email: "Email",
  role: "Role",
  clients: "Clients",
  is_active: "Status",
  last_seen: "Last Seen",
};
